import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import Dot from "./Dot"
import { scale, rhythm } from "../utils/typography"
import Image from "gatsby-image"

const Name = styled("span")`
  color: var(--primary);
`

const IntroWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${rhythm(1)} ${rhythm(1)};
`

const IntroText = styled("div")`
  flex: 1 1 300px;
  margin: 0 ${rhythm(1)};
`

const AndyImage = styled(Image)`
  max-width: 600px;
  flex: 1 1 300px;
  margin: 0 ${rhythm(1)} ${rhythm(1)};
`

const Home = () => {
  const data = useStaticQuery(graphql`
    query HomeQuery {
      andyImage: file(absolutePath: { regex: "/andy.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <>
      <h1
        style={{
          ...scale(1.5),
        }}
      >
        Hi, I'm{" "}
        <Name>
          Andy
          <Dot />
        </Name>
      </h1>
      <IntroWrapper>
        <AndyImage
          fluid={data.andyImage.childImageSharp.fluid}
          alt={author}
          imgStyle={{ height: "auto" }}
        />
        <IntroText>
          <p>
            <b>
              I'm a developer, living in London. I work for{" "}
              <a href="https://and.digital">AND Digital</a>, where I work with
              our clients to make lovely websites.
            </b>
          </p>
          <p>
            I'm most at home in front-end web development — I'm really
            interested in making things that people love to use and that make
            their lives (and the world) that bit better. I like to think that
            comes through in my attention to detail, eye for consistent and
            usable design, and passion for accessibility.
          </p>
          <p>
            I also enjoy coaching and mentoring other developers, and care
            deeply about making tech as open, welcoming and diverse a place as
            it can be.
          </p>
          <p>
            In my spare time I love discovering new music, patiently waiting for
            Man Utd to get good again, and playing innovative and experimental
            videogames.
          </p>
        </IntroText>
      </IntroWrapper>
    </>
  )
}

export default Home
